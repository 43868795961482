import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  parents: [],
  fundTypeId: null,
  parentId: null,
  isParentDisabled: false,
  fundTypes: []
};

const validateValues = (state, data) => {
  const filterValues = state.data.filter((item) => item.FundId === data.FundId);
  if (filterValues.length === 0) {
    state.data.push(data);
  }
};

const getParentsData = (state, value) => {
  const uniqueParents = [
    ...new Set(state.data.filter((item) => item.FundTypeId !== value)),
  ];
  state.parents = uniqueParents;
};

export const fundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    addData: (state, action) => {
      validateValues(state, action.payload);
    },
    getParentsByFund: (state, action) => {
      getParentsData(state, action.payload);
    },
    getFundTypeId: (state, action) => {
      state.fundTypeId = action.payload;
    },
    getParentId: (state, action) => {
      state.parentId = action.payload;
    },
    parentDisabled: (state, action) => {
      state.isParentDisabled = action.payload;
    },
    addFundTypes: (state, action) => {
      state.fundTypes = action.payload;
    },
  },
});

export const {
  addData,
  getParentsByFund,
  getFundTypeId,
  getParentId,
  isEdited,
  parentDisabled,
  addFundTypes
} = fundsSlice.actions;

export default fundsSlice.reducer;
