import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import Loadable from './Loadable'
// ========= || PROTECTED Themeroutes || ========= //
import ProtectRouteAdmin from './ProtectRouteAdmin'
import ProtectRouteReviewer from './ProtectRouteReviewer'
import ProtectRouteDataProvider from './ProtectRouteDataProvider'
import ProtectRouteSuperAdmin from './ProtectRouteSuperAdmin'
// ========= || LAYOUT || ========= //

const Layout = Loadable(lazy(() => import('../layouts/layout')))
// const AdminLayout = Loadable(lazy(()=>import('../layouts/AdminLayout')))



// ========= || ADMIN PAGES || ======== //

const AdminDashboard = Loadable(lazy(() => import('../pages/dashboard/adminDashboard')))
const Funds = Loadable(lazy(() => import('../pages/funds/funds')))
const ChildEntity = Loadable(lazy(()=> import('../pages/funds/fundsNew')))
const FundsAdd = Loadable(lazy(() => import('../pages/funds/fundsAdd')))
const FundsView = Loadable(lazy(() => import('../pages/funds/fundsView')))
const FundsEdit = Loadable(lazy(() => import('../pages/funds/fundsEdit')))
const FundsAssociateProperties = Loadable(lazy(() => import('../pages/funds/fundsAssociateProperties')))
const Properties = Loadable(lazy(() => import('../pages/properties/properties')))
const Dataset = Loadable(lazy(() => import('../pages/dataset/dataset')))
const PropertyMapping = Loadable(lazy(() => import('../pages/property_mapping/propertyMapping')))
const RulesEngine = Loadable(lazy(() => import('../pages/rules_engine/rulesEngine')))
const Users = Loadable(lazy(() => import('../pages/users/users')))
const Roles = Loadable(lazy(() => import('../pages/roles/role')))
const DatasetMappingInbox = Loadable(lazy(() => import('../pages/datasetMapping/datasetMappingInbox')))
const DatasetMappingForm = Loadable(lazy(() => import('../pages/datasetMapping/datasetMappingForm')))
const LookupKey = Loadable(lazy(() => import('../pages/system_master/lookup_key/lookupKey')))
const LookupValue = Loadable(lazy(() => import('../pages/system_master/lookup_value/lookupValue')))
const Glossary = Loadable(lazy(() => import('../pages/system_master/Glossary/glossary')))
const ServiceProviders = Loadable(lazy(() => import('../pages/serviceproviders/serviceProviders')))
const ManageFrequency = Loadable(lazy(() => import('../pages/manage_frequency/manageFrequency')))
const ChartsOfAC = Loadable(lazy(() => import('../pages/coa/coaMaster/chartsOfAC')))
const ACView = Loadable(lazy(() => import('../pages/coa/coaMaster/acView')))
const COAMapping = Loadable(lazy(() => import('../pages/coa/coaMapping/coaMapping')))
const MappingForm = Loadable(lazy(() => import('../pages/coa/coaMapping/coaMappingForm')))
const ConfigureWorkflow = Loadable(lazy(() => import('../pages/configureWorkflow/configureWorkflow')))
const ConfigureDataset = Loadable(lazy(() => import('../pages/configureWorkflow/configureDataset')))
const ConfigureModal = Loadable(lazy(() => import('../pages/configureWorkflow/configureModal')))
const ConfigurDatasetModal = Loadable(lazy(() => import('../pages/configureWorkflow/configurDatasetModal')))
const ViewUploadedFiles = Loadable(lazy(() => import('../pages/uploadedFiles/viewUploadedFiles')))
const IntegrationCenter = Loadable(lazy(() => import('../pages/integrationCenter/integrationCenter')))
const PowerBIReport = Loadable(lazy(() => import('../pages/reports/powerBIReport')))
const RentRollReport = Loadable(lazy(() => import('../pages/reports/rentRollReport')))
const LeaseExpirationReport = Loadable(lazy(() => import('../pages/reports/leaseExpirationReport')))
const VacancyAnalysisReport = Loadable(lazy(() => import('../pages/reports/vacancyAnalysisReport')))



// ========= || REVIEWER PAGES || ======== //

const ReviewerDashboard = Loadable(lazy(() => import('../pages/dashboard/reviewerDashboard')))

// ========= || SERVICE PROVIDER PAGES || ======== //

const ServiceProviderDashboard = Loadable(lazy(() => import('../pages/dashboard/serviceProviderDashboard')))

// ========= || COMMON PAGES || ======== //

const DashboardOrphanRecords = Loadable(lazy(() => import('../pages/dashboard/orphanRecords')))
const ViewNotifications = Loadable(lazy(() => import('../layouts/header/viewNotifications')))
const Profile = Loadable(lazy(() => import('../pages/profile/profile')))
// ========= || Themeroutes || ======== //

const Themeroutes = [
    {
        path: '/',
        element: <ProtectRouteAdmin Component={Layout} />,
        children: [
            {
                path: '/dashboard',
                name: 'AdminDashboard',
                exact: true,
                element: <AdminDashboard />
            },
            {
                path: '/funds',
                name: 'Funds',
                exact: true,
                element: <Funds />
            },
            {
                path : '/ChildEntity',
                name : 'ChildEntity',
                exact : true,
                element : <ChildEntity/>
            }, 
            {
                path: '/fundsAdd',
                name: 'FundsAdd',
                exact: true,
                element: <FundsAdd />
            },
            {
                path: '/entityview',
                name: 'FundsView',
                exact: true,
                element: <FundsView />
            },
            {
                path: '/fundsEdit',
                name: 'FundsEdit',
                exact: true,
                element: <FundsEdit />
            },
            {
                path: '/fundsassociateproperties',
                name: 'FundsAssociateProperties',
                exact: true,
                element: <FundsAssociateProperties />
            },
            {
                path: '/properties',
                name: 'Properties',
                exact: true,
                element: <Properties />
            },
            {
                path: '/dataset',
                name: 'Dataset',
                exact: true,
                element: <Dataset />
            },
            {
                path: '/property_mapping',
                name: 'PropertyMapping',
                exact: true,
                element: <PropertyMapping />
            },
            {
                path: '/rules_engine',
                name: 'RulesEngine',
                exact: true,
                element: <RulesEngine />
            },
            {
                path: '/alluser',
                name: 'Users',
                exact: true,
                element: <Users />
            },

            {
                path: '/mapping',
                name: 'DatasetMappingInbox',
                exact: true,
                element: <DatasetMappingInbox />
            },
            {
                path: '/datasetmapping',
                name: 'DatasetMappingForm',
                exact: true,
                element: <DatasetMappingForm />
            },

            {
                path: '/serviceProviders',
                name: 'ServiceProviders',
                exact: true,
                element: <ServiceProviders />
            },
            {
                path: '/managefrequency',
                name: 'ManageFrequency',
                exact: true,
                element: <ManageFrequency />
            },
            {
                path: '/chartsofAC',
                name: 'ChartsOfAC',
                exact: true,
                element: <ChartsOfAC />
            },
            {
                path: '/chartsofAC_view',
                name: 'ACView',
                exact: true,
                element: <ACView />
            },
            // {
            //     path : '/COA-mapping-new',
            //     name : 'COAMapping',
            //     exact : true,
            //     element : <COAMapping/>
            // },
            // {
            //     path : '/COA-mapping-form-map',
            //     name : 'MappingForm',
            //     exact : true,
            //     element : <MappingForm/>
            // },
            {
                path: '/configureWorkflow',
                name: 'ConfigureWorkflow',
                exact: true,
                element: <ConfigureWorkflow />
            },
            {
                path: '/ConfigureDataset',
                name: 'ConfigureDataset',
                exact: true,
                element: <ConfigureDataset />
            },
            {
                path: '/configureModal',
                name: 'ConfigureModal',
                exact: true,
                element: <ConfigureModal />
            },
            {
                path: '/configurDatasetModal',
                name: 'ConfigurDatasetModal',
                exact: true,
                element: <ConfigurDatasetModal />
            },
            {
                path: '/viewuploadedfiles',
                name: 'ViewUploadedFiles',
                exact: true,
                element: <ViewUploadedFiles />
            },
            {
                path: '/integrationCenter',
                name: 'IntegrationCenter',
                exact: true,
                element: <IntegrationCenter />
            },
            {
                path: '/report/trialbalance',
                name: 'PowerBIReport',
                exact: true,
                element: <PowerBIReport />
            },
            {
                path: '/report/rentroll',
                name: 'RentRollReport',
                exact: true,
                element: <RentRollReport />
            },
            {
                path: '/report/leaseexpiration',
                name: 'LeaseExpirationReport',
                exact: true,
                element: <LeaseExpirationReport />
            },
            {
                path: '/report/vacancyanalysis',
                name: 'VacancyAnalysisReport',
                exact: true,
                element: <VacancyAnalysisReport />
            },
            {
                path: '/roles',
                name: 'Roles',
                exact: true,
                element: <Roles />
            },
        ]

    },
    {
        // path : '/reviewer-dashboard',
        element: <ProtectRouteSuperAdmin Component={Layout} />,
        children: [

            {
                path: '/lookup_key',
                name: 'LookupKey',
                exact: true,
                element: <LookupKey />
            },
            {
                path: '/lookup_value',
                name: 'LookupValue',
                exact: true,
                element: <LookupValue />
            },
            {
                path: '/glossary',
                name: 'Glossary',
                exact: true,
                element: <Glossary />
            },
        ]

    },
    {
        path: '/reviewer-dashboard',
        element: <ProtectRouteReviewer Component={Layout} />,
        children: [
            {
                path: '/reviewer-dashboard',
                name: 'ReviewerDashboard',
                exact: true,
                element: <ReviewerDashboard />
            }
        ]

    },
    {
        path: '/',
        element: <ProtectRouteDataProvider Component={Layout} />,
        children: [
            {
                path: '/serviceprovider-dashboard',
                name: 'ServiceProviderDashboard',
                exact: true,
                element: <ServiceProviderDashboard />
            },
            // {
            //     path : '/serviceprovider-dashboard',
            //     name : 'ServiceProviderDashboard',
            //     exact : true,
            //     element : <ServiceProviderDashboard/>
            // }, 

        ]

    },
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/orphanRecords',
                name: 'DashboardOrphanRecords',
                exact: true,
                element: <DashboardOrphanRecords />
            },
            {
                path: '/viewNotifications',
                name: 'ViewNotifications',
                exact: true,
                element: <ViewNotifications />
            },
            {
                path: '/user-profile',
                name: 'Profile',
                exact: true,
                element: <Profile />
            },
            {
                path: '/COA-mapping-new',
                name: 'COAMapping',
                exact: true,
                element: <COAMapping />
            },
            {
                path: '/COA-mapping-form-map',
                name: 'MappingForm',
                exact: true,
                element: <MappingForm />
            },

        ]

    },
]

export default Themeroutes;


